import React, { useState } from "react";
import {
  useParams,
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import Flex from "app/components/Flex";
import Avatar from "app/components/Avatar";
import { H2, H3, P1 } from "app/components/Typography";
import Tabs from "app/components/Tabs";
import Button from "app/components/Button";
import { Routes } from "constants/routes";
import Icon, { Edit, Share } from "app/components/Icon";
import { Achievements } from "../Achievements";
import { ProfileMetrics } from "../ProfileMetrics";
import UploadedVideoGrid from "../UploadedVideoGrid";
import { useGetUserProfileWithVideos } from "./hooks";
import { LinksModal } from "../LinksModal";
import { ShareModal } from "../ShareModal";
import styles from "./styles.module.scss";
import { InstructorClasses } from "../InstructorClasses";
import { SocialMediaLinks } from "../SocialMediaLinks";

enum TabId {
  CLASSES = "classes",
  VIDEOS = "videos",
  ACHIEVEMENTS = "achievements",
}

const getTabs = (hasInstructorSlug: boolean) => {
  const baseTabs = [];

  if (hasInstructorSlug) {
    baseTabs.push({ id: TabId.CLASSES, label: "Classes" });
  }

  baseTabs.push(
    { id: TabId.VIDEOS, label: "Videos" },
    { id: TabId.ACHIEVEMENTS, label: "Achievements" }
  );

  return baseTabs;
};

export default function ProfileUser() {
  const { username } = useParams<{ username: string }>();
  const history = useHistory();
  const location = useLocation();
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const {
    profileData,
    videoUploads,
    videoUploadsPageInfo,
    fetchMoreUserVideos,
    uploadedVideosCount,
    isUserProfileLoading,
    hasUserProfileError,
  } = useGetUserProfileWithVideos();

  if (isUserProfileLoading || !profileData) {
    return <LoaderCentered />;
  }

  if (hasUserProfileError) {
    // @TODO: Add error state
    return <></>;
  }

  const {
    photoURL,
    name,
    totalLikesCount,
    bio,
    links,
    instructorSlug,
    isMe,
  } = profileData;

  const tabs = getTabs(!!instructorSlug);

  const getActiveTab = () => {
    if (location.pathname.includes("achievements")) {
      return TabId.ACHIEVEMENTS;
    }
    if (location.pathname.includes("videos")) {
      return TabId.VIDEOS;
    }
    return TabId.CLASSES;
  };

  const activeTab = getActiveTab();

  const handleTabChange = (tabId: TabId) => {
    if (tabId === TabId.ACHIEVEMENTS) {
      history.push(
        Routes.user_profile_achievements.replace(":username", username)
      );
    } else if (tabId === TabId.VIDEOS) {
      history.push(Routes.user_profile_videos.replace(":username", username));
    } else {
      history.push(Routes.user_profile_classes.replace(":username", username));
    }
  };

  const handleEditProfile = () => {
    history.push(Routes.account__settings);
  };

  const handleShareProfile = () => {
    setIsShareModalOpen(true);
  };

  return (
    <>
      <Flex width="100%" flexDirection="column" alignItems="center" px={3}>
        <Flex
          flexDirection="column"
          mt={{ _: 3, sm: 5 }}
          maxWidth="756px"
          alignItems="center"
          width="100%"
        >
          <Flex flexDirection="column" width="100%">
            <div className={styles.profileContainer}>
              <div className={styles.avatarWrapper}>
                <Avatar
                  src={photoURL}
                  alt="Avatar Profile Picture"
                  height={{ _: "64px", md: "140px" }}
                  width={{ _: "64px", md: "140px" }}
                />
                <div className={styles.userInfoWrapper}>
                  <H2>@{username}</H2>
                  <ProfileMetrics
                    postsCount={uploadedVideosCount}
                    likesCount={totalLikesCount}
                  />
                </div>
              </div>
              <div className={styles.userInfo}>
                {name && <H3>{name}</H3>}
                {bio && <P1 className={styles.bioText}>{bio}</P1>}
                {links && links.length > 0 && (
                  <SocialMediaLinks links={links} />
                )}
                <div className={styles.buttonsWrapper}>
                  {isMe && (
                    <Button variant="outline" onClick={handleEditProfile}>
                      <Flex alignItems="center" gap="8px">
                        <Icon as={Edit} width="16px" height="16px" />
                        Edit Profile
                      </Flex>
                    </Button>
                  )}
                  <Button variant="outline" onClick={handleShareProfile}>
                    <Flex alignItems="center" gap="8px">
                      <Icon as={Share} width="16px" height="16px" />
                      Share Profile
                    </Flex>
                  </Button>
                </div>
              </div>
            </div>

            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={handleTabChange}
            />

            <Switch>
              <Route exact path={Routes.user_profile}>
                {instructorSlug ? (
                  <Redirect
                    to={Routes.user_profile_classes.replace(
                      ":username",
                      username
                    )}
                  />
                ) : (
                  <Redirect
                    to={Routes.user_profile_videos.replace(
                      ":username",
                      username
                    )}
                  />
                )}
              </Route>
              <Route exact path={Routes.user_profile_videos}>
                <UploadedVideoGrid
                  videos={videoUploads}
                  fetchMore={fetchMoreUserVideos}
                  hasMore={videoUploadsPageInfo?.hasNextPage}
                />
              </Route>
              <Route exact path={Routes.user_profile_classes}>
                {instructorSlug && (
                  <InstructorClasses instructorSlug={instructorSlug} />
                )}
              </Route>
              <Route exact path={Routes.user_profile_achievements}>
                <Achievements username={username} />
              </Route>
            </Switch>
          </Flex>
        </Flex>
      </Flex>

      <LinksModal
        isOpen={isLinksModalOpen}
        onClose={() => setIsLinksModalOpen(false)}
        links={links || []}
      />

      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        username={username}
      />
    </>
  );
}
