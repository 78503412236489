import React from "react";
import Flex from "app/components/Flex";
import { P1, P2 } from "app/components/Typography";
import abbreviateCount from "helpers/abbreviateCount";
import Grid from "app/components/Grid";
import styles from "./styles.module.scss";

interface ProfileMetricsProps {
  postsCount?: number;
  likesCount?: number;
}

export function ProfileMetrics({
  postsCount = 0,
  likesCount = 0,
}: ProfileMetricsProps): JSX.Element {
  return (
    <Flex>
      <Grid gridGap="16px" gridTemplateColumns="1fr 1fr">
        <Flex className={styles.infoWrapper}>
          <P1 fontWeight="bold">{postsCount}</P1>
          <P2>Videos</P2>
        </Flex>
        <Flex className={styles.infoWrapper}>
          <P1 fontWeight="bold">{abbreviateCount(likesCount)}</P1>
          <P2>Likes</P2>
        </Flex>
      </Grid>
    </Flex>
  );
}
