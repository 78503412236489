import React from "react";
import Flex from "app/components/Flex";
import { H2, P1 } from "app/components/Typography";
import Modal from "app/components/Modal";
import Icon, {
  Youtube,
  Instagram,
  FAFacebook as Facebook,
  Link,
} from "app/components/Icon";
import CloseModalButton from "app/components/Modal/CloseModalButton";
import { UserProfileLink } from "services/graphql";
import styles from "./styles.module.scss";
import { cleanUrl } from "../utils";

interface LinksModalProps {
  isOpen: boolean;
  onClose: () => void;
  links: UserProfileLink[];
}

interface LinkItemProps {
  link: UserProfileLink;
}

function LinkItem({ link }: LinkItemProps) {
  const getIconComponent = (urlToCheck: string) => {
    if (urlToCheck.includes("youtube.com")) {
      return Youtube;
    }
    if (urlToCheck.includes("instagram.com")) {
      return Instagram;
    }
    if (urlToCheck.includes("facebook.com")) {
      return Facebook;
    }
    return Link;
  };

  const IconComponent = getIconComponent(link.url);
  const formattedUrl = link.url.startsWith("http")
    ? link.url
    : `https://${link.url}`;
  const displayUrl = cleanUrl(link.url);

  return (
    <Flex alignItems="center" className={styles.linkItem}>
      <Icon as={IconComponent} width="24px" height="24px" mr={2} />
      <a href={formattedUrl} target="_blank" rel="noopener noreferrer">
        <Flex flexDirection="column">
          {link.title ? (
            <>
              <P1 fontWeight="500">{link.title}</P1>
              <P1 color="monochrome.6">{displayUrl}</P1>
            </>
          ) : (
            <P1 color="monochrome.6">{displayUrl}</P1>
          )}
        </Flex>
      </a>
    </Flex>
  );
}

export function LinksModal({ isOpen, onClose, links }: LinksModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onBackgroundClick={onClose}
      onEscapeKeydown={onClose}
    >
      <Flex flexDirection="column" width="100%" maxWidth="500px">
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <H2>Links</H2>
          <CloseModalButton onClick={onClose} variant="light" />
        </Flex>
        <Flex flexDirection="column" gap="16px">
          {links.map(link => (
            <LinkItem key={link.url} link={link} />
          ))}
        </Flex>
      </Flex>
    </Modal>
  );
}
