import React from "react";
import classNames from "classnames";
import { P1 } from "../Typography";
import styles from "./styles.module.scss";

interface Tab<T extends string = string> {
  id: T;
  label: string;
}

interface TabsProps<T extends string = string> {
  tabs: Tab<T>[];
  activeTab: T;
  onChange: (tabId: T) => void;
}

export default function Tabs<T extends string = string>({
  tabs,
  activeTab,
  onChange,
}: TabsProps<T>) {
  return (
    <div className={styles.container}>
      {tabs.map(({ id, label }) => (
        <div
          key={id}
          className={classNames(styles.tabItem, {
            [styles.active]: id === activeTab,
          })}
          onClick={() => onChange(id)}
        >
          <P1
            className={classNames(styles.tabText, {
              [styles.active]: id === activeTab,
            })}
          >
            {label}
          </P1>
        </div>
      ))}
    </div>
  );
}
