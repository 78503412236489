import React, { FC, useState } from "react";
import { Link, Instagram, Youtube } from "app/components/Icon";
import { UserProfileLink } from "services/graphql";
import { LinksModal } from "../LinksModal";
import { cleanUrl } from "../utils";
import styles from "./styles.module.scss";

interface SocialMediaLinksProps {
  links: UserProfileLink[];
}

export const SocialMediaLinks: FC<SocialMediaLinksProps> = ({ links }) => {
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);

  const socialMediaLinks = {
    instagram: links.find(link => link.url.includes("instagram.com")),
    youtube: links.find(link => link.url.includes("youtube.com")),
    tiktok: links.find(link => link.url.includes("tiktok.com")),
  };

  // Check if any social media links exist
  const hasSocialMediaLinks = Object.values(socialMediaLinks).some(
    link => link !== undefined
  );

  const otherLinks = links.filter(
    link =>
      !link.url.includes("instagram.com") &&
      !link.url.includes("youtube.com") &&
      !link.url.includes("tiktok.com")
  );

  const renderOtherLinks = () => {
    if (!otherLinks.length) {
      return null;
    }

    // If we have social media links and other links, show "View all links"
    if (hasSocialMediaLinks) {
      return (
        <button
          type="button"
          onClick={() => setIsLinksModalOpen(true)}
          className={styles.linkText}
        >
          <div className={styles.linkContainer}>
            <Link className={styles.icon} width={24} height={24} />
            <span>+ view all links</span>
          </div>
        </button>
      );
    }

    // If no social media links, show the normal shortened link display
    if (otherLinks.length === 1) {
      return (
        <div className={styles.linkContainer}>
          <Link className={styles.icon} width={24} height={24} />
          <a
            className={styles.linkText}
            href={otherLinks[0].url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {cleanUrl(otherLinks[0].url)}
          </a>
        </div>
      );
    }

    return (
      <button
        type="button"
        onClick={() => setIsLinksModalOpen(true)}
        className={styles.linkText}
      >
        <div className={styles.linkContainer}>
          <Link className={styles.icon} width={24} height={24} />
          <span>
            {cleanUrl(otherLinks[0].url)} +{otherLinks.length - 1} more
          </span>
        </div>
      </button>
    );
  };

  if (!links?.length) {
    return null;
  }

  console.log({ links });

  return (
    <>
      <div className={styles.container}>
        {hasSocialMediaLinks && (
          <div className={styles.socialMediaContainer}>
            {socialMediaLinks.instagram && (
              <a
                href={socialMediaLinks.instagram.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMediaLink}
                aria-label="Instagram Profile"
              >
                <Instagram
                  className={styles.icon}
                  data-icon="instagram"
                  width={24}
                  height={24}
                />
              </a>
            )}
            {socialMediaLinks.youtube && (
              <a
                href={socialMediaLinks.youtube.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMediaLink}
                aria-label="YouTube Channel"
              >
                <Youtube
                  className={styles.icon}
                  data-icon="youtube"
                  width={24}
                  height={24}
                />
              </a>
            )}
            {socialMediaLinks.tiktok && (
              <a
                href={socialMediaLinks.tiktok.url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialMediaLink}
                aria-label="TikTok Profile"
              >
                <svg
                  className={styles.icon}
                  data-icon="tiktok"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z" />
                </svg>
              </a>
            )}
          </div>
        )}
        {renderOtherLinks()}
      </div>

      <LinksModal
        isOpen={isLinksModalOpen}
        onClose={() => setIsLinksModalOpen(false)}
        links={links}
      />
    </>
  );
};
