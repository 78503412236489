import React, { useState } from "react";
import Flex from "app/components/Flex";
import Modal from "app/components/Modal";
import { H2, P1 } from "app/components/Typography";
import CloseModalButton from "app/components/Modal/CloseModalButton";
import { env } from "helpers/env/get";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "app/components/Tooltip";
import {
  FAFacebook,
  TwitterWithCircle,
  Reddit,
  Pinterest,
  ContentCopy,
} from "app/components/Icon";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  username: string;
}

export function ShareModal({ isOpen, onClose, username }: Props) {
  const [copyLinkMessage, setCopyLinkMessage] = useState("Copy Link");
  const profileUrl = `${env("PUBLIC_DOMAIN")}/@${username}`;
  const shareMessage = `Check out ${username}'s profile on STEEZY`;

  return (
    <Modal
      isOpen={isOpen}
      onBackgroundClick={onClose}
      onEscapeKeydown={onClose}
    >
      <Flex
        flexDirection="column"
        width="100%"
        minWidth="300px"
        maxWidth="700px"
      >
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <H2>Share Profile</H2>
          <CloseModalButton onClick={onClose} variant="light" />
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <div
            className={styles.socialWrapper}
            onClick={() =>
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${profileUrl}`,
                "blank"
              )
            }
          >
            <FAFacebook data-icon="facebook" className={styles.icon} />
            <P1 className={styles.socialText}>Share on Facebook</P1>
          </div>
          <div
            className={styles.socialWrapper}
            onClick={() =>
              window.open(
                `https://twitter.com/intent/tweet?text=${shareMessage}&url=${profileUrl}`,
                "blank"
              )
            }
          >
            <TwitterWithCircle data-icon="twitter" className={styles.icon} />
            <P1 className={styles.socialText}>Share on Twitter</P1>
          </div>
          <div
            className={styles.socialWrapper}
            onClick={() =>
              window.open(
                `http://www.reddit.com/submit?title=${shareMessage}&url=${profileUrl}`,
                "blank"
              )
            }
          >
            <Reddit data-icon="reddit" className={styles.icon} />
            <P1 className={styles.socialText}>Share on Reddit</P1>
          </div>
          <div
            className={styles.socialWrapper}
            onClick={() =>
              window.open(
                `https://pinterest.com/pin-builder/?url=${profileUrl}&description=${shareMessage}`,
                "blank"
              )
            }
          >
            <Pinterest data-icon="pinterest" className={styles.icon} />
            <P1 className={styles.socialText}>Share on Pinterest</P1>
          </div>
          <CopyToClipboard
            text={profileUrl}
            onCopy={() => setCopyLinkMessage("Link Copied!")}
          >
            <Tooltip overlay={copyLinkMessage}>
              <div
                className={styles.socialWrapper}
                onMouseLeave={() => setCopyLinkMessage("Copy Link")}
              >
                <ContentCopy data-icon="copy" className={styles.icon} />
                <P1 className={styles.socialText}>Copy link to clipboard</P1>
              </div>
            </Tooltip>
          </CopyToClipboard>
        </Flex>
      </Flex>
    </Modal>
  );
}
